
import { Component, Vue } from "vue-property-decorator";

interface SnackbarInterface {
  snackbar: boolean;
  snack: Record<string, unknown>;
}

type Unwatcher = () => void;

@Component
export default class Snackbar extends Vue implements SnackbarInterface {
  private _unwatchSnack!: Unwatcher;

  snackbar = false;
  snack = { status: null, text: null };

  get snackbarHeight() {
    return this.$vuetify.breakpoint.mdAndUp ? 64 : 56;
  }

  created() {
    this._unwatchSnack = this.$store.watch(
      state => state.snack.snackMessage,
      snack => {
        if (snack) {
          this.snackbar = true;
          this.snack = snack;

          this.$store.commit("snack/setSnack", null);
        }
      }
    );
  }

  beforeDestroy() {
    this._unwatchSnack();
  }
}
